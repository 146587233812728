import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ImageContainer } from "../../shared/components/ImageContainer";
import {
  HeaderImgage1,
  HeaderImgage2,
  HomeCoverBg,
} from "../../assets/images/image";
import { WikiLogo } from "../../assets/icons/icons";
import NewsletterSection from "../../main/NewsletterSection";
import Search from "../../shared/components/Search";
import Chip from "../../shared/components/Chip";
import ComponentCard from "./components/ComponentCard";
import { ButtonPrimary } from "../../shared/components/button/Buttons";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../App";
import { colors } from "../../utils/colors";
import { getUserById } from "../../services/user";

type Props = {};

const Home = (props: Props) => {
  const navigate = useNavigate();
  const { userDetails } = useGlobalContext();
  const { searchProductBy, setSearchProductBy } = useGlobalContext();

  const [loading, setLoading] = useState<boolean>(true);
  const [components, setComponents] = useState<any>();
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleSearch = (searchBy?: string) => {
    setSearchProductBy(searchBy || "");
    navigateToSearch();
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      navigateToSearch();
    }
  };

  const handleTagClick = (tag: any) => {
    setSearchProductBy(tag || "");
    navigateToSearch(tag);
  };

  const navigateToSearch = (tag?: any) => {
    navigate("/search", {
      state: { tag: tag },
    });
  };

  const fetchAllComponents = async () => {
    const list: any = localStorage.getItem("componentsList");
    if (list) {
      const sortedList = JSON.parse(list)?.paginatedComponents.sort(
        (a: any, b: any) => {
          return (
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          );
        }
      );
      setComponents(sortedList);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllComponents();
  }, []);

  useEffect(() => {}, [searchProductBy]);

  const getUserSubscriptionsDetails = async (id: string) => {
    const response = await getUserById(id);
    const subscriptions = response?.data?.subscriptions;

    if (subscriptions?.length > 0) {
      setUserSubscriptions(subscriptions);
      for (let subscription of subscriptions) {
        if (subscription.isPaid === true && subscription.status === "ACTIVE") {
          setIsSubscribed(true);
        }
      }
    }
  };

  useEffect(() => {
    if (userDetails) {
      getUserSubscriptionsDetails(userDetails._id);
    }
  }, [userDetails]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingY: "80px",
        paddingX: "80px",
        height: "100%",
        position: "relative",
      }}
    >
      <img
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          width: "100%",
          top: "-120px",
          maxHeight: "50%",
          zIndex: -100,
        }}
        alt="background"
        src={HomeCoverBg}
      />
      {/* header Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "42px",
          textAlign: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "24px",
            textAlign: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              maxWidth: "795px",
              width: "100%",
              paddingBottom: "26px",
            }}
          >
            <ImageContainer
              style={{
                width: "202px",
                height: "86px",
                position: "absolute",
                left: "0px",
                bottom: "-26px",
              }}
              title="image"
            >
              {HeaderImgage1}
            </ImageContainer>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                padding: "8px",
                boxShadow: "inset 0px 0px 0px 2px #3D3D3D",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                  padding: "16px",
                  bgcolor: "#292929",
                }}
              >
                <ImageContainer
                  style={{ width: "110px", height: "110px" }}
                  height="110px"
                  title="Main Logo"
                >
                  {WikiLogo}
                </ImageContainer>
              </Box>
            </Box>

            <ImageContainer
              style={{
                width: "136px",
                height: "86px",
                position: "absolute",
                aspectRatio: "136px/66px",
                right: 0,
                bottom: 14,
              }}
              title="image"
            >
              {HeaderImgage2}
            </ImageContainer>
          </Box>

          <Typography
            sx={{
              fontSize: "58px",
              fontWeight: "600",
              color: "text.secondary",
              background:
                "linear-gradient(81deg, rgba(255,255,255,1) 0%, rgba(48,48,48,1) 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              // maxWidth: "35%",
            }}
            variant="h1"
          >
            Digital Designer <br /> and Creative Developer
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "24px",
          }}
        >
          <Box
            sx={{
              minWidth: "536px",
              height: "54px",
            }}
          >
            <Search
              style={{
                backgroundColor: colors.primaryGrey,
                width: "100%",
                border: "none",
                height: "100%",
                boxShadow: "inset 0px 0px 0px 2px #3D3D3D",
                borderRadius: "12px",
                padding: "0px 20px 0px 15px",
                outline: "none",
              }}
              placeholder="Search"
              onChange={(e: any) => {
                handleSearch(e?.target?.value);
              }}
              onKeyDown={handleKeyPress}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              maxWidth: "517px",
              gap: "8px",
            }}
          >
            {[
              "Components",
              "Headers",
              "Footer",
              "Navigation",
              "FAQ",
              "Testimonial",
              "Team",
              "Feature",
              "How It Works",
              "Blogs",
              "Banner",
            ].map((tag) => (
              <Chip
                key={tag}
                sx={{ cursor: "pointer" }}
                title={tag}
                onClick={() => handleTagClick(tag)}
              />
            ))}
          </Box>
        </Box>
      </Box>

      {/* Content Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingY: "80px",
          alignItems: "center",
          flex: 1,
        }}
      >
        {loading ? (
          <Box
            sx={{
              minWidth: "100%",
              width: "100%",
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              size={24}
              sx={{
                color: "white",
                marginRight: "12px",
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flex: 1,
              width: "100%",
              gap: "40px",
            }}
          >
            <Typography variant="h3" color={"white"}>
              Latest Components
            </Typography>

            <Box
              sx={{
                display: "grid",
                width: "100%",
                boxSizing: "border-box",
                gridGap: "16px",
                gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))", // Adjust card size and fit into available space
                "@media screen and (max-width: 579px)": {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  flexWrap: "wrap",
                },
              }}
            >
              {components?.map((data: any, index: number) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      maxWidth: { xs: "360px", sm: "auto", lg: "360px" },
                      minWidth: { xs: "320px", sm: "100%", md: "100%" },
                    }}
                  >
                    <ComponentCard data={data} isSubscribed={isSubscribed} />
                  </Box>
                );
              })}
            </Box>

            <ButtonPrimary
              onClick={() => {
                navigate("/search");
              }}
              sx={{ width: "150px", height: "54px", mt: "48px" }}
              label="View More"
            />
          </Box>
        )}
      </Box>
      <NewsletterSection />
    </Box>
  );
};

export default Home;
