import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useSettingsContext } from "../Setting";
import { ArrowRightIcon } from "../../../assets/icons/ArrowRightIcon";
import { useEffect } from "react";

export const SettingsNavigationListItems = () => {
  const { activeTab, setActiveTab } = useSettingsContext();

  useEffect(() => {}, [activeTab]);
  return (
    <List
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        "&.MuiList-root": {
          paddingY: "0px !important",
        },
      }}
    >
      {[
        {
          id: 1,
          text: "Profile",
          badgeText: null,
        },
        {
          id: 2,
          text: "Security",
          badgeText: null,
        },
        {
          id: 3,
          text: "Membership",
          badgeText: null,
        },
        // {
        //   id: 4,
        //   text: "Payment Method",
        //   badgeText: null,
        // },
        // {
        //   id: 5,
        //   text: "Billing",
        //   badgeText: null,
        // },
        // {
        //   id: 6,
        //   text: "Notifications",
        //   badgeText: null,
        // },
      ].map((menuItem: any) => {
        return (
          <ListItem
            onClick={() => {
              setActiveTab(menuItem?.id);
            }}
            key={menuItem?.id}
            sx={{
              paddingX: "0px",
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              bgcolor:
                activeTab === menuItem?.id
                  ? "primary.main"
                  : "rgba(255, 255, 255, 0.06)",
              boxShadow:
                activeTab === menuItem?.id
                  ? "none"
                  : "inset 0px 0px 0px 1px #3D3D3D",
              borderRadius: "10px",
              alignItems: "center",
              cursor: "pointer",
              "&.MuiListItem-root": {
                paddingY: "0px !important",
              },
            }}
          >
            <ListItemButton
              disableRipple
              sx={{
                height: "56px",
                justifyContent: "initial",
                borderRadius: "10px",
                backgroundColor: "transparent",
                "&:hover": {},
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flex: "1",
                  color: "#FFFFFF",
                }}
              >
                <ListItemText
                  sx={{
                    width: "100%",
                    color: activeTab === menuItem?.id ? "black" : "white",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      color: activeTab === menuItem?.id ? "black" : "#FFFFFF",
                      // fontWeight: 500,
                      width: "100%",
                      fontSize: "15px",
                      zIndex: 999,
                    }}
                  >
                    {menuItem.text}
                  </Typography>
                </ListItemText>
              </Box>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  justifyContent: "center",
                  width: "fit-content",
                }}
              >
                <ArrowRightIcon
                  color={activeTab === menuItem?.id ? "black" : "white"}
                />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

export default SettingsNavigationListItems;
