import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import { ImageContainer } from "../shared/components/ImageContainer";
import {
  EyeLightIcon,
  EyeOffLightIcon,
  GoogleIcon,
} from "../assets/icons/icons";
import { colors } from "../utils/colors";
import DefaultLoading from "../shared/Loading/DefaultLoading";
import TextInput from "../shared/components/TextInput";
import { registerUser } from "../services/authentication";
import { validate_if_not_empty } from "../utils/extensions";
import useEnterKeyPress from "../utils/useEnterKeyPress";
import { useGlobalContext } from "../App";

type Props = {
  activeDialog: any;
  handleDialog?: any;
};

const SignupDialog: React.FC<Props> = (props) => {
  const { setActiveLoginDialog, setActiveSignupDialog } = useGlobalContext();

  // loading state
  const [showLoading, setShowLoading] = useState(false);

  // user details states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");

  const [passwordVisible, setPasswordVisible] = useState(false);
  const signinButtonRef = useRef(null);

  // Error handling states
  const [error, setError] = useState<string>();

  const inputField1Ref = useRef(null);
  const inputField2Ref = useRef(null);

  const handleEnterKeyPress = () => {
    // !showLoading && login();
  };

  const validateFields = () => {
    if (
      validate_if_not_empty(firstName, "loginFirstNameNotExist") &&
      validate_if_not_empty(lastName, "loginLastNameNotExist") &&
      validate_if_not_empty(
        loginId,
        "emailNotExist",
        "",
        "Please enter a valid email address."
      ) &&
      validate_if_not_empty(password, "loginPassNotExist")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleRegister = async () => {
    setShowLoading(true);
    if (validateFields()) {
      try {
        setError("");

        const data = {
          email: loginId,
          password: password,
          firstName: firstName,
          lastName: lastName,
        };

        // Await the loginUser function call
        const res: any = await registerUser(data);

        if (res) {
          setActiveSignupDialog(false);
          setActiveLoginDialog(true);
          setShowLoading(false);
        }
      } catch (error: any) {
        console.log(error, error.code);
      } finally {
        setShowLoading(false);
      }
    } else {
      setShowLoading(false);
    }
  };

  // Use the custom hook
  useEnterKeyPress(handleEnterKeyPress);

  useEffect(() => {}, [props?.activeDialog]);
  return (
    props?.activeDialog && (
      <>
        <Box
          sx={{
            position: "fixed", // Ensure the backdrop covers the entire screen
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.60)",
            zIndex: 5000, // Set zIndex below the dialog
          }}
        >
          {/* Apply blur effect to only this background */}
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              bgcolor: "rgba(0, 0, 0, 0.60)",
              filter: "blur(62px)",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "fixed", // Position the dialog above the backdrop
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)", // Center the dialog
            zIndex: 6000, // Higher zIndex than the backdrop
          }}
        >
          <Box
            sx={{
              padding: "42px",
              height: "fit-content",
              width: { xs: "100%", sm: "488px", lg: "488px" },
              maxWidth: { xs: "100%", sm: "488px", lg: "488px" },
              maxHeight: "907px",
              bgcolor: "#1B1B1B",
              borderRadius: "24px",
              border: "1px solid #3D3D3D",
              cursor: "default",
              position: "relative",
              overflowY: "scroll",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* Dialog content */}
            <CloseSharp
              onClick={(e) => {
                e.stopPropagation();
                props?.handleDialog();
              }}
              sx={{
                position: "absolute",
                right: "20px",
                top: "20px",
                cursor: "pointer",
                color: "white",
              }}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: { xs: "100%", sm: "403px", lg: "403px" },
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "12px",
                  textAlign: "center",
                  width: "100%",
                  mb: "32px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    color: "text.secondary",
                  }}
                  variant="h3"
                >
                  Sign up
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "300",
                    color: "rgba(255, 255, 255, 0.5)",
                  }}
                  variant="subtitle1"
                >
                  Already have an account?{" "}
                  <span
                    onClick={() => {
                      setActiveSignupDialog(false);
                      setActiveLoginDialog(true);
                    }}
                    style={{
                      fontWeight: "300",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    Sign in here
                  </span>
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "12px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "24px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "13px",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        borderRadius: "12px",
                        flex: 1,
                      }}
                    >
                      <TextInput
                        lableStyles={{ fontSize: "12px", fontWeight: "600" }}
                        label="First Name"
                        placeholder="Enter your first name"
                        onChange={(e: any) => {
                          setFirstName(e.target.value);
                        }}
                        onNext={inputField1Ref}
                        value={firstName}
                        required
                      />
                      <Typography
                        className="err_field"
                        id="loginFirstNameNotExist"
                        color="red"
                        variant="body2"
                      ></Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        borderRadius: "12px",
                        flex: 1,
                      }}
                    >
                      <TextInput
                        lableStyles={{ fontSize: "12px", fontWeight: "600" }}
                        label="Last Name"
                        placeholder="Enter your last name"
                        onChange={(e: any) => {
                          setLastName(e.target.value);
                        }}
                        onNext={inputField1Ref}
                        value={lastName}
                        required
                      />
                      <Typography
                        className="err_field"
                        id="loginLastNameNotExist"
                        color="red"
                        variant="body2"
                      ></Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      borderRadius: "12px",
                      maxWidth: "480px",
                    }}
                  >
                    <TextInput
                      lableStyles={{ fontSize: "12px", fontWeight: "600" }}
                      label="Email"
                      placeholder="Enter Your Email/ Employee ID"
                      onChange={(e: any) => {
                        setLoginId(e.target.value);
                      }}
                      onNext={inputField1Ref}
                      value={loginId}
                      required
                    />
                    <Typography
                      className="err_field"
                      id="loginIdNotExist"
                      color="red"
                      variant="body2"
                    ></Typography>
                  </Box>

                  <Box
                    borderRadius={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      borderRadius: "12px",
                      maxWidth: "480px",
                    }}
                  >
                    <TextInput
                      lableStyles={{ fontSize: "12px", fontWeight: "600" }}
                      label="Password"
                      placeholder="Enter password"
                      icononclick={() => setPasswordVisible(!passwordVisible)}
                      icon={passwordVisible ? EyeLightIcon : EyeOffLightIcon}
                      type={!passwordVisible ? "password" : "text"}
                      onChange={(e: any) => {
                        setPassword(e.target.value);
                      }}
                      onNext={inputField2Ref}
                      value={password}
                      required
                      iconstyles={{ width: "17px", height: "15px" }}
                    />
                    <Typography
                      className="err_field"
                      id="loginPassNotExist"
                      color="red"
                      variant="body2"
                    ></Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "5px",
                  maxWidth: "480px",
                  marginTop: "32px",
                }}
              >
                <Button
                  id="signinButton"
                  sx={{
                    width: "100%",
                    height: "56px",
                    textAlign: "center",
                    textTransform: "none",
                    bgcolor: "primary.main",
                    color: "#FFFFFF",
                    borderRadius: "16px",
                    paddingY: "16px",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                  }}
                  ref={signinButtonRef}
                  onClick={() => handleRegister()}
                  endIcon={
                    showLoading && (
                      <Box
                        sx={{
                          width: "42px",
                          height: "42px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <DefaultLoading />
                      </Box>
                    )
                  }
                >
                  <Typography
                    sx={{ color: "text.primary", fontWeight: "700" }}
                    variant="subtitle1"
                  >
                    {!showLoading && "Continue"}
                  </Typography>
                </Button>
                {error && (
                  <Typography
                    sx={{ width: "100%" }}
                    fontWeight={"400"}
                    variant="caption"
                    color="#E03137"
                  >
                    {error}
                  </Typography>
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "24px",
                  mt: "24px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    gap: "30px",
                  }}
                >
                  <hr
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.5)",
                      border: "none",
                      flex: 1,
                      height: "0.5px",
                    }}
                  />
                  <Typography variant="body2" sx={{ color: "#A2AB9C" }}>
                    OR
                  </Typography>
                  <hr
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.5)",
                      border: "none",
                      flex: 1,
                      height: "0.5px",
                    }}
                  />
                </Box>

                <Button
                  id="signinButton"
                  sx={{
                    width: "100%",
                    height: "56px",
                    textAlign: "center",
                    textTransform: "none",
                    bgcolor: colors.primaryGrey,
                    boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                    color: "#FFFFFF",
                    borderRadius: "16px",
                    paddingY: "16px",
                    "&:hover": {
                      bgcolor: colors.primaryGrey,
                    },
                  }}
                  ref={signinButtonRef}
                  onClick={() => {}}
                  startIcon={
                    <ImageContainer
                      width={"24px"}
                      height={"24px"}
                      title="google"
                    >
                      {GoogleIcon}
                    </ImageContainer>
                  }
                >
                  <Typography
                    sx={{ color: "text.secondary", fontWeight: "700" }}
                    variant="subtitle1"
                  >
                    {"Log in with Google"}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  );
};

export default SignupDialog;
