import { Box, Typography } from "@mui/material";
import React from "react";
import Chip from "./../shared/components/Chip";
import NewsletterSection from "./../main/NewsletterSection";
// import { capturePayment, createPayment } from "../services/authentication";
import MembershipPlans from "./setting/tabs/membership/membershipDialog/MembershipPlans";

type Props = {};

const Pricing = (props: Props) => {
  // const navigate = useNavigate();
  // let userId = "";
  // const userJson: any = Cookies.get("user");

  // if (userJson) {
  //   let user: any = JSON.parse(userJson);
  //   userId = user["user"].id;
  // }
  // Get the location object
  // const location = useLocation();

  // const queryParams = new URLSearchParams(location.search);
  // const subscriptionId = queryParams.get("subscription_id");

  // TabPanel handling states and functions
  // const [value, setValue] = useState(0);

  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };

  // const handleSubscribeRegular = async (planId: string) => {
  //   const data = { userId, planId };

  //   try {
  //     const response: any = await createPayment(data);
  //     const url = response.data;

  //     if (url) {
  //       window.location.href = url; // Redirect to PayPal's checkout page
  //     }
  //   } catch (error) {
  //     console.error("Error creating PayPal order:", error);
  //   }
  // };

  // const handleCapturePayment = async (token: any) => {
  //   const data = { userId, token };
  //   try {
  //     // const response = await capturePayment(data);
  //     navigate("/pricing");
  //   } catch (error) {
  //     console.error("Error capturing PayPal order:", error);
  //   }
  // };

  // useEffect(() => {
  //   if (subscriptionId && userId) {
  //     handleCapturePayment(subscriptionId);
  //   }
  // }, [subscriptionId]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "80px",
        alignItems: "center",
        gap: "80px",
        width: "100%",
      }}
    >
      {/* ------Header Section------ */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <Chip
          sx={{
            height: "48px",
            borderRadius: "79px",
            width: "182px",
          }}
          title="Your Go-To Guide"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: "16px",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: "600",
              fontSize: "58px",
              width: "40%",
              lineHeight: "110%",
              background:
                "linear-gradient(81deg, rgba(255,255,255,1) 0%, rgba(48,48,48,1) 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
            variant="h1"
          >
            Flexible Plans to Fit Your Needs
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: "rgba(255, 255, 255, 0.5)",
              fontWeight: "400",
              width: "40%",
            }}
          >
            By choosing Ulwiki, you will streamline your design process with
            essential tools, advanced features, and efficient, time-saving
            solutions.
          </Typography>
        </Box>
      </Box>

      {/* ------Pricong Section------ */}
      <MembershipPlans />

      {/* -----Newsletter Section----- */}
      <Box sx={{ width: "100%" }}>
        <NewsletterSection />
      </Box>
    </Box>
  );
};

export default Pricing;

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{ width: "100%" }}
      className="HideScrollbar"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ flex: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}
