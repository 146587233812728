import React, { createContext, useContext, useEffect, useState } from "react";
import "./App.css";
import {
  NavLink,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { darkTheme, lightTheme } from "./utils/theme";
import { ThemeProvider } from "@mui/material/styles";
import Main from "./main/Main";
import Login from "./login/Login";
import SignUp from "./signup/SignUp";
import ResetPassword from "./resetPassword/ResetPassword";
import Cookies from "js-cookie";
import { getUserById } from "./services/user";
import SearchScreen from "./pages/search/SearchScreen";
import { Box, CssBaseline } from "@mui/material";
import { getAllComponents } from "./services/component";
import DefaultLoading from "./shared/Loading/DefaultLoading";

interface GlobalContextType {
  setActiveNavigation: any;
  activeNavigation: any;
  navigations: any;
  setNavigations: any;
  breadcrumbs: any;
  setBreadcrumbs: any;
  breadCrumbsLoading: any;
  setBreadCrumbsLoading: any;
  handleBreadCrumbsLoading: any;
  userDetails: any;
  setUserDetails: any;
  searchProductBy: string;
  setSearchProductBy: any;
  activeLoginDialog: any;
  setActiveLoginDialog: any;
  activeSignupDialog: any;
  setActiveSignupDialog: any;
  activeResetDialog: any;
  setActiveResetDialog: any;
}
export const GlobalContext = createContext<GlobalContextType | undefined>(
  undefined
);
export const useGlobalContext = (): any => useContext(GlobalContext)!;

function App() {
  const [currentTheme] = useState<"light" | "dark">("dark");
  const theme = currentTheme === "light" ? lightTheme : darkTheme;

  const [loading, setLoading] = useState<boolean>(true);

  const [breadCrumbsLoading, setBreadCrumbsLoading] = useState<boolean>(false);
  const [breadcrumbs, setBreadcrumbs] = useState<any>([
    <NavLink to="/">Home</NavLink>,
  ]);

  // User Login, logout and reset password dialogs visiblity handling states
  const [activeLoginDialog, setActiveLoginDialog] = useState<boolean>(false);
  const [activeSignupDialog, setActiveSignupDialog] = useState<boolean>(false);
  const [activeResetDialog, setActiveResetDialog] = useState<boolean>(false);

  // Loged in user details handling state
  const [userDetails, setUserDetails] = useState("");

  // All/Active navigation handling states
  const [navigations, setNavigations] = useState<any>([]);
  const [activeNavigation, setActiveNavigation] = useState<any>(() => {
    try {
      const storedValue = window.localStorage.getItem("ACTIVE_NAVIGATION");
      return storedValue ? JSON.parse(storedValue) : navigations;
    } catch (error) {
      console.error("Error parsing JSON from localStorage:", error);
      return navigations;
    }
  });

  // Component Search handling state
  const [searchProductBy, setSearchProductBy] = useState<string>("");
  const handleBreadCrumbsLoading: any = (loading: boolean) => {
    setBreadCrumbsLoading(loading);
  };

  const getComponents = async () => {
    try {
      await getAllComponents()
        .then((res: any) => {
          const { paginatedComponents, totalComponents } = res;

          // Remove figmaCode from each object in paginatedComponents
          const filteredComponents = paginatedComponents.map(
            ({ figmaCode, ...rest }: any) => rest
          );

          const dataToStore = {
            paginatedComponents: filteredComponents,
            totalComponents,
          };
          localStorage.removeItem("componentsList");
          localStorage.setItem("componentsList", JSON.stringify(dataToStore));
        })
        .catch((err: any) => {
          console.log("err", err);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("componentsList")) {
      setLoading(false);
    }

    getComponents();
    const fetchUserDetails = async (id: string) => {
      const res: any = await getUserById(id);
      if (res) {
        setUserDetails(res?.data);
      }
    };

    const userCookie: any = Cookies.get("user");
    if (userCookie) {
      const user = JSON.parse(userCookie);
      if (user) {
        fetchUserDetails(user?.user?.id);
      }
    } else {
      Cookies.remove("jstoken");
    }
  }, []);

  const globalContextProps = {
    setActiveNavigation,
    activeNavigation,
    navigations,
    setNavigations,
    breadCrumbsLoading,
    setBreadCrumbsLoading,
    breadcrumbs,
    setBreadcrumbs,
    handleBreadCrumbsLoading,
    userDetails,
    setUserDetails,
    searchProductBy,
    setSearchProductBy,
    activeLoginDialog,
    setActiveLoginDialog,
    activeSignupDialog,
    setActiveSignupDialog,
    activeResetDialog,
    setActiveResetDialog,
  };

  if (loading)
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "62px",
            height: "62px",
          }}
        >
          <DefaultLoading width="62px" height="62px" />
        </Box>
      </Box>
    );

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GlobalContext.Provider value={globalContextProps}>
          <CssBaseline />
          <Router>
            <Routes>
              <Route path="/*" element={<Main />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/search" element={<SearchScreen />} />
            </Routes>
          </Router>
        </GlobalContext.Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
