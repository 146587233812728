import { Box } from "@mui/material";
import SettingsNavigationListItems from "./SettingsNavigationListItems";
import { useEffect } from "react";
import useEnterKeyPress from "../../../utils/useEnterKeyPress";
import { useSettingsContext } from "../Setting";

function SettingsSidenavigation() {
  const tabsCount = 5;

  const { activeTab, setActiveTab } = useSettingsContext();

  const handleEnterKeyPress = () => {
    activeTab < tabsCount ? handleClickNext() : <></>;
  };

  // Use the custom hook
  useEnterKeyPress(handleEnterKeyPress);

  const handleClickNext = () => {
    setActiveTab(activeTab + 1);
  };

  useEffect(() => {}, [activeTab]);

  return (
    <Box
      sx={{
        maxWidth: { xs: "100%", sm: "312px" },
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "24px",
      }}
    >
      <Box
        sx={{
          maxWidth: { xs: "100%", sm: "312px" },
          width: "100%",
        }}
        display="flex"
        justifyContent="flex-start"
        bgcolor="rgba(255, 255, 255, 0.06)"
        borderRadius="16px"
        padding="24px"
        height="fit-content"
      >
        <Box
          display="flex"
          padding="0px"
          justifyContent="flex-start"
          flexDirection="row"
          alignItems="start"
          width="100%"
        >
          <SettingsNavigationListItems />
        </Box>
      </Box>
    </Box>
  );
}

export default SettingsSidenavigation;
