import axios from "axios";
import Cookies from "js-cookie";
import { HOST_NAME } from "../utils/constants";

export const createComponent = async (data: any, files: File[]) => {
  return new Promise((resolve, reject) => {
    // Create a FormData object to hold the data and files
    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append("files", file);
    });

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    // Configure the request
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/component`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
        "Content-Type": "multipart/form-data", // Important for file uploads
      },
      data: formData,
    };

    // Send the request
    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllComponents = (filters?: any) => {
  return new Promise((resolve, reject) => {
    const pageNumber =
      filters?.page !== "" &&
        filters?.page !== undefined &&
        filters?.page !== null
        ? filters?.page
        : "";
    const pageSize =
      filters?.pageSize !== "" &&
        filters?.pageSize !== undefined &&
        filters?.pageSize !== null
        ? filters?.pageSize
        : "";
    const tagsArray = filters?.tags?.length > 0 ? filters?.tags : [];
    const tagsJson =
      tagsArray?.length !== 0
        ? `[${tagsArray?.map((element: any) => `"${element}"`).join(", ")}]`
        : "";

    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/component?search=${filters?.searchBy || ""
        }&page=${pageNumber}&pageSize=${pageSize}&tags=${tagsJson}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };
    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteComponent = (id: string) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/component/${id}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateComponent = (id: string, data: any) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/component/${id}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createComponentFeedback = async (data: any) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/feedback`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const createComponentReport = (data: any) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/problem`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadDocument = async (file: any) => {
  let formData = new FormData();
  formData.append("files", file);
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/documents/upload`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    const response = await axios.request(config);
    return response?.data;
  } catch (error) {
    return error;
  }
};


export const getUserSubscriptions = (id: any) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/subscription/${id}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios.request(config)
      .then((response) => {
        resolve(response?.data?.data)
      })
      .catch((error) => {
        reject(error)
      });

  });
};

export const createSubscription = (data: any) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/create-payment`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        resolve(response?.data)
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  })
}

export const getUserPurchases = (id: any) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/transaction/${id}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios.request(config)
      .then((response) => {
        console.log(response.data);
        resolve(response?.data?.data)
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });

  });
};