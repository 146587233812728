import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import TextInput from "../../../../shared/components/TextInput";
import { ButtonPrimary } from "../../../../shared/components/button/Buttons";
import { EyeLightIcon, EyeOffLightIcon } from "../../../../assets/icons/icons";
import { resetPassword } from "../../../../services/authentication";
import { toastError, toastSuccess } from "../../../../utils/ToastMessage";
import { useGlobalContext } from "../../../../App";
import { validate_if_not_empty } from "../../../../utils/extensions";

type Props = {};

const Security = (props: Props) => {
  const { userDetails } = useGlobalContext();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  // Password visibility handling states
  const [currentPasswordVisible, setCurrentPasswordVisible] =
    useState<boolean>(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState<boolean>(false);
  const [confirmNewPasswordVisible, setconfirmNewPasswordVisible] =
    useState<boolean>(false);

  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");

  const handleSavePassword = async () => {
    if (
      validate_if_not_empty(currentPassword, "currentPassErrId") &&
      validate_if_not_empty(newPassword, "newPassErrId") &&
      validate_if_not_empty(confirmNewPassword, "confirmNewPassErrId")
    ) {
      if (newPassword === confirmNewPassword) {
        setConfirmPasswordErr("");
        await resetPassword(
          {
            oldPassword: currentPassword,
            newPassword: newPassword,
          },
          userDetails?.id
        )
          .then((res: any) => {
            if (res?.response?.data?.success || res?.success) {
              toastSuccess("Successfully reset password.", res?.message);
            } else {
              toastError(
                "Ops! Something went wrong.",
                res?.response?.data?.message
              );
            }
          })
          .catch((err: any) => {
            console.log(err);
            toastError(
              "Ops! Something went wrong.",
              "Failed to reset the password."
            );
          });

        setCurrentPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
      } else {
        setConfirmPasswordErr(
          "Confirm password does not match your new password."
        );
      }
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "48px 56px",
        minWidth: "460px",
        maxWidth: "460px",
        borderRadius: "14px",
        bgcolor: "rgba(255, 255, 255, 0.06)",
        gap: "32px",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <Typography
          sx={{ color: "#FFFFFF", fontWeight: "600" }}
          variant="subtitle1"
        >
          Level Up Your Login
        </Typography>
        <Typography sx={{ color: "rgba(255, 255, 255, 0.5)" }} variant="body1">
          For your account's safety, it's a great idea to update your password
          regulary. here's how to create a new one:
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "5px",
          }}
        >
          <TextInput
            inputStyles={{
              backgroundColor: "rgba(255, 255, 255, 0.06)",
              boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
              fontSize: "16px",
            }}
            lableStyles={{
              fontSize: "16px !important",
              fontWeight: "600",
            }}
            label="Current Password"
            placeholder="Current Password"
            value={currentPassword}
            onChange={(e: any) => {
              setCurrentPassword(e.target.value);
            }}
            icononclick={() =>
              setCurrentPasswordVisible(!currentPasswordVisible)
            }
            iconstyles={{ width: "24px", height: "24px" }}
            icon={currentPasswordVisible ? EyeLightIcon : EyeOffLightIcon}
            type={!currentPasswordVisible ? "password" : "text"}
          />
          <Typography
            id="currentPassErrId"
            className="err_field"
            color="red"
            variant="body2"
          ></Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "5px",
          }}
        >
          <TextInput
            inputStyles={{
              backgroundColor: "rgba(255, 255, 255, 0.06)",
              boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
              fontSize: "16px",
            }}
            lableStyles={{
              fontSize: "16px !important",
              fontWeight: "600",
            }}
            label="New Password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e: any) => {
              setNewPassword(e.target.value);
            }}
            icononclick={() => setNewPasswordVisible(!newPasswordVisible)}
            iconstyles={{ width: "24px", height: "24px" }}
            icon={newPasswordVisible ? EyeLightIcon : EyeOffLightIcon}
            type={!newPasswordVisible ? "password" : "text"}
          />
          <Typography
            id="newPassErrId"
            className="err_field"
            color="red"
            variant="body2"
          ></Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "5px",
          }}
        >
          <TextInput
            inputStyles={{
              backgroundColor: "rgba(255, 255, 255, 0.06)",
              boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
              fontSize: "16px",
            }}
            lableStyles={{
              fontSize: "16px !important",
              fontWeight: "600",
            }}
            label="Confirm New Password"
            placeholder="Confirm New Password"
            value={confirmNewPassword}
            onChange={(e: any) => {
              setConfirmNewPassword(e.target.value);
            }}
            icononclick={() => {
              setconfirmNewPasswordVisible(!confirmNewPasswordVisible);
            }}
            iconstyles={{ width: "24px", height: "24px" }}
            icon={confirmNewPasswordVisible ? EyeLightIcon : EyeOffLightIcon}
            type={!confirmNewPasswordVisible ? "password" : "text"}
          />
          <Typography
            id="confirmNewPassErrId"
            className="err_field"
            color="red"
            variant="body2"
          >
            {confirmPasswordErr}
          </Typography>
          <Typography className="err_field" color="red" variant="body2">
            {confirmPasswordErr}
          </Typography>
        </Box>

        <ButtonPrimary
          onClick={() => {
            handleSavePassword();
          }}
          label="Save"
        />

        <Typography
          onClick={() => {}}
          variant="subtitle1"
          sx={{
            color: "#E25454",
            textAlign: "center",
            width: "100%",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          Forgot password
        </Typography>
      </Box>
    </Box>
  );
};

export default Security;
