import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../../../shared/components/button/Buttons";
import { TickCircleIconSvg } from "../../../../assets/icons/TickCircleIconSvg";
import ConfirmCancelMembershipDialog from "./cancelMembership/ConfirmCancelMembershipDialog";
import CancelMembershipDialog from "./cancelMembership/CancelMembershipDialog";

type Props = {
  data: any;
  handleRefresh: any;
};

const ActiveMembershipCard = (props: Props) => {
  const [confirmCancelMembershipDialog, setConfirmCancelMembershipDialog] =
    useState<boolean>(false);
  const [activeCancelMembershipDialog, setActiveCancelMembershipDialog] =
    useState<boolean>(false);

  const handleCloseConfirmCancelMembershipDialog = (res?: any) => {
    if (res?.action === "CANCEL") {
      setActiveCancelMembershipDialog(true);
    }
    setConfirmCancelMembershipDialog(false);
  };

  const handleCloseCancelMembershipDialog = () => {
    setActiveCancelMembershipDialog(false);
    props?.handleRefresh();
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {confirmCancelMembershipDialog && (
        <ConfirmCancelMembershipDialog
          handleDialog={handleCloseConfirmCancelMembershipDialog}
          activeDialog={confirmCancelMembershipDialog}
        />
      )}
      {activeCancelMembershipDialog && (
        <CancelMembershipDialog
          handleDialog={handleCloseCancelMembershipDialog}
          activeDialog={activeCancelMembershipDialog}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "48px 56px",
          borderRadius: "14px",
          bgcolor: "rgba(255, 255, 255, 0.06)",
          maxWidth: "460px",
          minWidth: "460px",
          gap: "24px",
        }}
      >
        {/* -----Price section----- */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <Typography
              sx={{ fontWeight: "600", color: "primary.main" }}
              variant="body1"
            >
              {props?.data?.type}
            </Typography>
          </Box>

          <Typography
            sx={{
              color: "primary.main",
              fontWeight: "600",
              display: "flex",
              gap: "6px",
            }}
            variant="subtitle1"
          >
            $
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                gap: "6px",
              }}
            >
              <span
                style={{
                  fontSize: "57px",
                  color: "rgba(255, 255, 255, 0.8)",
                  lineHeight: "100%",
                }}
              >
                {props?.data?.price}
              </span>
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "white",
                }}
              >
                /month
              </span>
            </Box>
          </Typography>
        </Box>

        {/* *****Seperator***** */}
        <Box
          sx={{
            flex: 1,
            height: "1px",
            border: "1px solid rgba(255, 255, 255, 0.1)",
          }}
        />

        {/* ------Pricing Details------ */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          {props?.data?.features?.map((feature: any, index: number) => {
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{ fontWeight: "600", color: "white" }}
                  variant="h4"
                >
                  {feature?.title}
                </Typography>
                {feature?.list?.map((item: string, i: number) => {
                  return (
                    <Box
                      key={i}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                      }}
                    >
                      <TickCircleIconSvg />
                      <Typography
                        sx={{ fontWeight: "400", color: "white" }}
                        variant="subtitle1"
                      >
                        {item}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            );
          })}
        </Box>

        {/* ------Action buttons container------ */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            mt: "8px",
          }}
        >
          <ButtonPrimary label="Upgrade" />
          <ButtonSecondary
            onClick={() => {
              setConfirmCancelMembershipDialog(true);
            }}
            label="Cancel Membership"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ActiveMembershipCard;
