import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PurchaseCard from "./PurchaseCard";
import { getUserPurchases } from "../../services/component";
import { useGlobalContext } from "../../App";
import { ImageContainer } from "../../shared/components/ImageContainer";
import { RainIcon } from "../../assets/icons/icons";
import DefaultLoading from "../../shared/Loading/DefaultLoading";

type Props = {};

const Purchases = (props: Props) => {
  const { userDetails } = useGlobalContext();

  const [loading, setLoading] = useState(false);
  const [purchaseList, setPurchaseList] = useState<any>([
    // {
    //   _id: "67021b5b9de779c746c11d64",
    //   user: "66ffd12bb278211768e1f880",
    //   paypalSubscriptionId: "I-HHP82018XBRS",
    //   planId: "P-41937763HX824305WM3YN5OI",
    //   status: "ACTIVE",
    //   isPaid: true,
    //   planType: "MONTHLY",
    //   startDate: "2024-10-06T05:07:32.000Z",
    //   endDate: "2024-11-05T10:00:00.000Z",
    //   __v: 0,
    // },
  ]);

  useEffect(() => {
    getUserPurchases(userDetails?.id)
      ?.then((res: any) => {
        console.log(res);
        setLoading(false);
        setPurchaseList(res);
      })
      .catch((err: any) => {
        console.error(err);
        setLoading(false);
      });
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingY: "80px",
        gap: "32px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "24px",
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            background:
              "linear-gradient(81deg, rgba(255,255,255,1) 0%, rgba(48,48,48,1) 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
          variant="h3"
        >
          Your Purchases
        </Typography>
        <Typography
          sx={{
            fontWeight: "400",
            color: "rgba(255, 255, 255, 0.5)",
          }}
          variant="subtitle1"
        >
          Manage your subscriptions, downloads and invoices
        </Typography>
      </Box>

      {loading ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "42px",
              height: "42px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DefaultLoading />
          </Box>
        </Box>
      ) : purchaseList?.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "24px",
            backgroundColor: "rgba(255, 255, 255, 0.06)",
            padding: "24px",
            gap: "32px",
            maxWidth: "690px",
            width: "100%",
          }}
        >
          {purchaseList?.map((item: any, index: number) => {
            return (
              <>
                <PurchaseCard data={item} />

                {index !== purchaseList?.length - 1 && (
                  // *****Seperator*****
                  <Box
                    sx={{
                      flex: 1,
                      height: "1px",
                      border: "1px solid rgba(255, 255, 255, 0.06)",
                    }}
                  />
                )}
              </>
            );
          })}
        </Box>
      ) : (
        <Box
          sx={{
            width: "270px",
            height: "270px",
            aspectRatio: "1/1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ImageContainer title="rain">{RainIcon}</ImageContainer>
        </Box>
      )}
    </Box>
  );
};

export default Purchases;
