import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CrownIconSvg } from "../../../../../assets/icons/CrownIconSvg";
import { ButtonPrimary } from "../../../../../shared/components/button/Buttons";
import { TickCircleIconSvg } from "../../../../../assets/icons/TickCircleIconSvg";
import { createSubscription } from "../../../../../services/component";
import { useGlobalContext } from "../../../../../App";
import { REGULAR_PLAN_ID } from "../../../../../utils/constants";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { capturePayment } from "../../../../../services/authentication";

type Props = {};

const MembershipPlans = (props: Props) => {
  const { userDetails, setActiveLoginDialog, activeLoginDialog } =
    useGlobalContext();

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const subscriptionId = queryParams.get("subscription_id");

  const [chooseRegularPlanLoading, setChooseRegularPlanLoading] =
    useState<boolean>(false);
  const [activePlanId, setActivePlanId] = useState<string>("");
  // TabPanel handling states and functions
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleSubscribeUser = async (planId: string) => {
    setChooseRegularPlanLoading(true);
    await createSubscription({
      userId: userDetails?.id,
      planId: planId,
    })
      .then((res: any) => {
        if (res?.data) {
          window.location.replace(res?.data);
        }
        setChooseRegularPlanLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setChooseRegularPlanLoading(false);
      });
  };

  useEffect(() => {
    if (Cookies.get("jstoken") && activePlanId) {
      handleSubscribeUser(activePlanId);
    }
  }, [activeLoginDialog]);

  const handleCapturePayment = async (token: any) => {
    const userId = userDetails.id;
    const data = { userId, token };
    try {
      const response = await capturePayment(data);
      navigate("/");
    } catch (error) {
      console.error("Error capturing PayPal order:", error);
    }
  };

  useEffect(() => {
    if (subscriptionId && userDetails?.id) {
      handleCapturePayment(subscriptionId);
    }
  }, [subscriptionId]);

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        gap: "40px",
        // scale: 0.9,
      }}
    >
      {/* {activeLoginDialog && <LoginDialog activeDialog={activeLoginDialog} />} */}
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        TabIndicatorProps={{ style: { display: "none" } }}
        TabScrollButtonProps={{
          style: {
            color: "white",
          },
        }}
        visibleScrollbar={true}
        sx={{
          borderColor: "divider",
          fontWeight: "500",
          color: "white",
          bgcolor: "rgba(255, 255, 255, 0.1)",
          padding: "6px",
          borderRadius: "14px",
          flex: 1,
          width: "fit-content",
          minHeight: "fit-content",
          "& .MuiTabs-flexContainer": {
            display: "flex",
            flexDirection: "row",
            gap: "12px",
            padding: "0px",
          },
        }}
        aria-label="scrollable auto tabs example"
      >
        <Tab
          disableRipple
          sx={{
            textTransform: "none",
            fontWeight: "500",
            color: "white",
            padding: "0px !important",
          }}
          label={
            <Box
              sx={{
                bgcolor: value === 0 ? "#CCFF00" : "transparent",
                color: value === 0 ? "black" : "white",
                borderRadius: "14px",
                minHeight: "63px !important",
                maxHeight: "63px !important",
                width: "228px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mr: "0px",
                gap: "8px",
              }}
            >
              <Typography fontWeight={600} variant="subtitle1">
                Annually
              </Typography>

              <Typography
                sx={{
                  textAlign: "center",
                  width: "93px",
                  height: "33px",
                  borderRadius: "59px",
                  bgcolor: value === 0 ? "#000000" : "#FFFFFF",
                  color: value === 0 ? "#FFFFFF" : "black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                fontWeight={600}
                variant="body2"
              >
                Save 40%
              </Typography>
            </Box>
          }
        />
        <Tab
          disableRipple
          sx={{
            textTransform: "none",
            fontWeight: "500",
            color: "white",
            padding: "0px !important",
          }}
          label={
            <Box
              sx={{
                bgcolor: value === 1 ? "#CCFF00" : "transparent",
                color: value === 1 ? "black" : "white",
                borderRadius: "14px",
                minHeight: "63px !important",
                maxHeight: "63px !important",
                width: "228px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0px !important",
              }}
            >
              <Typography fontWeight={600} variant="subtitle1">
                Monthly
              </Typography>
            </Box>
          }
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          {/* --------pricing cards container-------- */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              gap: "36px",
            }}
          >
            {/* ------Price card 1------ */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "40px",
                borderRadius: "14px",
                bgcolor: "rgba(255, 255, 255, 0.06)",
                maxWidth: "428px",
                minWidth: "428px",
                gap: "24px",
              }}
            >
              {/* -----Price section----- */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <Box
                    sx={{
                      width: "48px",
                      height: "48px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      bgcolor: "rgba(255, 255, 255, 0.1)",
                    }}
                  >
                    <CrownIconSvg />
                  </Box>
                  <Typography
                    sx={{ fontWeight: "600", color: "white" }}
                    variant="h4"
                  >
                    Regular plan
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontSize: "58px",
                    color: "white",
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                  variant="h1"
                >
                  $6{" "}
                  <span
                    style={{
                      fontSize: "18px",
                      color: "rgba(255, 255, 255, 0.8)",
                    }}
                  >
                    /month *billed yearly
                  </span>
                </Typography>

                <ButtonPrimary sx={{ width: "100%" }} label="Choose plan" />
              </Box>

              {/* *****Seperator***** */}
              <Box
                sx={{
                  flex: 1,
                  height: "1px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                }}
              />

              {/* ------Pricing Details------ */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{ fontWeight: "600", color: "white" }}
                  variant="h4"
                >
                  Components
                </Typography>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <TickCircleIconSvg />{" "}
                  <Typography
                    sx={{ fontWeight: "400", color: "white" }}
                    variant="subtitle1"
                  >
                    Premium component 40+
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{ fontWeight: "600", color: "white" }}
                  variant="h4"
                >
                  Apps & Tools
                </Typography>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <TickCircleIconSvg />{" "}
                  <Typography
                    sx={{ fontWeight: "400", color: "white" }}
                    variant="subtitle1"
                  >
                    Chrome Extension
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <TickCircleIconSvg />{" "}
                  <Typography
                    sx={{ fontWeight: "400", color: "white" }}
                    variant="subtitle1"
                  >
                    Figma Plugin
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{ fontWeight: "600", color: "white" }}
                  variant="h4"
                >
                  Other
                </Typography>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <TickCircleIconSvg />{" "}
                  <Typography
                    sx={{ fontWeight: "400", color: "white" }}
                    variant="subtitle1"
                  >
                    Priority support
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <TickCircleIconSvg />{" "}
                  <Typography
                    sx={{ fontWeight: "400", color: "white" }}
                    variant="subtitle1"
                  >
                    Discount to partner tools
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* ------Price card 2------ */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "40px",
                borderRadius: "14px",
                bgcolor: "rgba(255, 255, 255, 0.06)",
                maxWidth: "428px",
                minWidth: "428px",
                gap: "24px",
              }}
            >
              {/* -----Price section----- */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <Box
                    sx={{
                      width: "48px",
                      height: "48px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      bgcolor: "rgba(255, 255, 255, 0.1)",
                    }}
                  >
                    <CrownIconSvg />
                  </Box>
                  <Typography
                    sx={{ fontWeight: "600", color: "white" }}
                    variant="h4"
                  >
                    Lifetime plan
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontSize: "58px",
                    color: "white",
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                  variant="h1"
                >
                  $150
                </Typography>

                <ButtonPrimary sx={{ width: "100%" }} label="Choose plan" />
              </Box>

              {/* *****Seperator***** */}
              <Box
                sx={{
                  flex: 1,
                  height: "1px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                }}
              />

              {/* ------Pricing Details------ */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{ fontWeight: "600", color: "white" }}
                  variant="h4"
                >
                  Components
                </Typography>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <TickCircleIconSvg />{" "}
                  <Typography
                    sx={{ fontWeight: "400", color: "white" }}
                    variant="subtitle1"
                  >
                    Unlimited Premium component
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{ fontWeight: "600", color: "white" }}
                  variant="h4"
                >
                  Apps & Tools
                </Typography>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <TickCircleIconSvg />{" "}
                  <Typography
                    sx={{ fontWeight: "400", color: "white" }}
                    variant="subtitle1"
                  >
                    Chrome Extension
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <TickCircleIconSvg />{" "}
                  <Typography
                    sx={{ fontWeight: "400", color: "white" }}
                    variant="subtitle1"
                  >
                    Figma Plugin
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{ fontWeight: "600", color: "white" }}
                  variant="h4"
                >
                  Other
                </Typography>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <TickCircleIconSvg />{" "}
                  <Typography
                    sx={{ fontWeight: "400", color: "white" }}
                    variant="subtitle1"
                  >
                    Priority support
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <TickCircleIconSvg />{" "}
                  <Typography
                    sx={{ fontWeight: "400", color: "white" }}
                    variant="subtitle1"
                  >
                    Discount to partner tools
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          {/* --------pricing cards container-------- */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              gap: "36px",
            }}
          >
            {/* ------Price card 1------ */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "40px",
                borderRadius: "14px",
                bgcolor: "rgba(255, 255, 255, 0.06)",
                maxWidth: "428px",
                minWidth: "428px",
                gap: "24px",
              }}
            >
              {/* -----Price section----- */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <Box
                    sx={{
                      width: "48px",
                      height: "48px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      bgcolor: "rgba(255, 255, 255, 0.1)",
                    }}
                  >
                    <CrownIconSvg />
                  </Box>
                  <Typography
                    sx={{ fontWeight: "600", color: "white" }}
                    variant="h4"
                  >
                    Regular
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontSize: "58px",
                    color: "white",
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                  variant="h1"
                >
                  $10{" "}
                  <span
                    style={{
                      fontSize: "18px",
                      color: "rgba(255, 255, 255, 0.8)",
                    }}
                  >
                    /month
                  </span>
                </Typography>

                <ButtonPrimary
                  isLoading={chooseRegularPlanLoading}
                  onClick={() => {
                    setActivePlanId(REGULAR_PLAN_ID);
                    !Cookies.get("jstoken")
                      ? setActiveLoginDialog(true)
                      : handleSubscribeUser(REGULAR_PLAN_ID);
                  }}
                  sx={{ width: "100%" }}
                  label="Choose plan"
                />
              </Box>

              {/* *****Seperator***** */}
              <Box
                sx={{
                  flex: 1,
                  height: "1px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                }}
              />

              {/* ------Pricing Details------ */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{ fontWeight: "600", color: "white" }}
                  variant="h4"
                >
                  Components
                </Typography>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <TickCircleIconSvg />
                  <Typography
                    sx={{ fontWeight: "400", color: "white" }}
                    variant="subtitle1"
                  >
                    Premium component 40+
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{ fontWeight: "600", color: "white" }}
                  variant="h4"
                >
                  Apps & Tools
                </Typography>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <TickCircleIconSvg />{" "}
                  <Typography
                    sx={{ fontWeight: "400", color: "white" }}
                    variant="subtitle1"
                  >
                    Chrome Extension
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <TickCircleIconSvg />{" "}
                  <Typography
                    sx={{ fontWeight: "400", color: "white" }}
                    variant="subtitle1"
                  >
                    Figma Plugin
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{ fontWeight: "600", color: "white" }}
                  variant="h4"
                >
                  Other
                </Typography>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <TickCircleIconSvg />{" "}
                  <Typography
                    sx={{ fontWeight: "400", color: "white" }}
                    variant="subtitle1"
                  >
                    Priority support
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <TickCircleIconSvg />{" "}
                  <Typography
                    sx={{ fontWeight: "400", color: "white" }}
                    variant="subtitle1"
                  >
                    Discount to partner tools
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* ------Price card 2------ */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "40px",
                borderRadius: "14px",
                bgcolor: "rgba(255, 255, 255, 0.06)",
                maxWidth: "428px",
                minWidth: "428px",
                gap: "24px",
              }}
            >
              {/* -----Price section----- */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <Box
                    sx={{
                      width: "48px",
                      height: "48px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      bgcolor: "rgba(255, 255, 255, 0.1)",
                    }}
                  >
                    <CrownIconSvg />
                  </Box>
                  <Typography
                    sx={{ fontWeight: "600", color: "white" }}
                    variant="h4"
                  >
                    Lifetime
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontSize: "58px",
                    color: "white",
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                  variant="h1"
                >
                  $150
                </Typography>

                <ButtonPrimary sx={{ width: "100%" }} label="Choose plan" />
              </Box>

              {/* *****Seperator***** */}
              <Box
                sx={{
                  flex: 1,
                  height: "1px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                }}
              />

              {/* ------Pricing Details------ */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{ fontWeight: "600", color: "white" }}
                  variant="h4"
                >
                  Components
                </Typography>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <TickCircleIconSvg />{" "}
                  <Typography
                    sx={{ fontWeight: "400", color: "white" }}
                    variant="subtitle1"
                  >
                    Unlimited Premium component
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{ fontWeight: "600", color: "white" }}
                  variant="h4"
                >
                  Apps & Tools
                </Typography>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <TickCircleIconSvg />{" "}
                  <Typography
                    sx={{ fontWeight: "400", color: "white" }}
                    variant="subtitle1"
                  >
                    Chrome Extension
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <TickCircleIconSvg />{" "}
                  <Typography
                    sx={{ fontWeight: "400", color: "white" }}
                    variant="subtitle1"
                  >
                    Figma Plugin
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{ fontWeight: "600", color: "white" }}
                  variant="h4"
                >
                  Other
                </Typography>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <TickCircleIconSvg />{" "}
                  <Typography
                    sx={{ fontWeight: "400", color: "white" }}
                    variant="subtitle1"
                  >
                    Priority support
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <TickCircleIconSvg />{" "}
                  <Typography
                    sx={{ fontWeight: "400", color: "white" }}
                    variant="subtitle1"
                  >
                    Discount to partner tools
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </TabPanel>
    </Box>
  );
};

export default MembershipPlans;

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{ width: "100%" }}
      className="HideScrollbar"
      // sx={{ maxHeight: SECTION_MIN_HEIGHT }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ flex: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}
