import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactMarkdown from "react-markdown";

type Props = {
  question: string;
  description: string;
};

const FaqQuestion = (props: Props) => {
  return (
    <Accordion
      sx={{
        width: "100%",
        bgcolor: "#1B1B1B",
        backgroundImage: "none",
        padding: "26px 32px 26px 0px",
        borderRadius: "14px !important",
        minHeight: "0px",
        display: "flex",
        flexDirection: "column",
        boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
        marginTop: "0px !important",
        margin: "0px !important",
        "&::before": {
          height: "0px !important", // Remove the line
          display: "none !important", // Hide the pseudo-element
        },
        "& .MuiAccordionSummary-root.Mui-expanded": {
          margin: "0px",
          minHeight: "0px !important",
        },
      }}
    >
      {/* MuiPaper-root- */}
      <AccordionSummary
        sx={{
          width: "100%",
          bgcolor: "transparent",
          color: "white",
          padding: "0px",
          fontSize: "24px",
          borderLeft: "6px solid #CCFF00",
          minHeight: "40px !important",
          paddingLeft: "26px",
          mt: "0px",
          "& .Mui-expanded": {
            paddingY: "6px",
            margin: "0px",
          },
          "& .MuiSvgIcon-root": {
            width: "24px",
            height: "24px",
          },
          "& .MuiAccordionSummary-content": {
            margin: "0px !important",
          },
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        {props?.question}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          gap: "8px",
          padding: "0px",
          color: "rgba(255, 255, 255, 0.5)",
          lineHeight: "120%",
          paddingLeft: "32px",
          mt: "18px",
          mb: "4px",
        }}
      >
        <ReactMarkdown>{props?.description}</ReactMarkdown>
      </AccordionDetails>
    </Accordion>
  );
};

export default FaqQuestion;
