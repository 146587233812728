import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import TextAreaInput from "../../shared/components/TextAreaInput";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../shared/components/button/Buttons";
import { MessageQuestionIconSvg } from "../../assets/icons/MessageQuestionIconSvg";
import { createComponentFeedback } from "../../services/component";
import { useGlobalContext } from "../../App";
import { toastError, toastSuccess } from "../../utils/ToastMessage";

type Props = {
  activeDialog: any;
  handleDialog?: any;
  data: any;
};

const FeedbackDialog: React.FC<Props> = (props) => {
  const { userDetails } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState<string>("");

  const handleSubmitFeedback = async () => {
    setLoading(true);
    await createComponentFeedback({
      userId: userDetails?.id,
      message: description,
      componentId: props?.data?.id,
    })
      .then((res: any) => {
        toastSuccess("Successfully sent.", "Successfully sent the feedback.");
      })
      .catch((err: any) => {
        toastError("Ops! Something went wrong.", "Failed to send feedback.");
      });

    setLoading(false);
    props?.handleDialog();
  };

  useEffect(() => {}, [props?.activeDialog]);
  return (
    props?.activeDialog && (
      <>
        <Box
          sx={{
            position: "fixed", // Ensure the backdrop covers the entire screen
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.60)",
            zIndex: 5000, // Set zIndex below the dialog
          }}
        >
          {/* Apply blur effect to only this background */}
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              border: "1px solid red",
              bgcolor: "rgba(0, 0, 0, 0.60)",
              filter: "blur(62px)",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "fixed", // Position the dialog above the backdrop
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)", // Center the dialog
            zIndex: 6000, // Higher zIndex than the backdrop
          }}
        >
          <Box
            sx={{
              width: "777px",
              maxWidth: "777px",
              padding: "40px",
              height: "auto",
              bgcolor: "#1B1B1B",
              borderRadius: "24px",
              border: "1px solid #3D3D3D",
              cursor: "default",
              position: "relative",
            }}
          >
            {/* Dialog content */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
              }}
            >
              {/* Title section */}
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  mb: "32px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "16px",
                  }}
                >
                  <MessageQuestionIconSvg color="#CCFF00" />
                  <Typography variant="h4" sx={{ color: "text.secondary" }}>
                    Give feedback
                  </Typography>
                </Box>

                <CloseSharp
                  onClick={(e) => {
                    e.stopPropagation();
                    props?.handleDialog();
                  }}
                  sx={{
                    position: "absolute",
                    right: "40px",
                    top: "40px",
                    cursor: "pointer",
                    color: "white",
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                  width: "100%",
                }}
              >
                <Box>
                  <TextAreaInput
                    textinputstyles={{ gap: "12px" }}
                    inputStyles={{
                      height: "206px",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                    placeholderStyle={{ fontSize: "16px", lineHeight: "24px" }}
                    value={description}
                    onChange={(e: any) => {
                      setDescription(e.target.value);
                    }}
                    placeholder="Your feedback helps us understand what we're doing well and where we have opportunities to grow. 
                    Thank you for taking the time to share your thoughts with us!"
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <ButtonSecondary
                    onClick={(e) => {
                      e.stopPropagation();
                      props?.handleDialog();
                    }}
                    sx={{
                      height: "52px",
                      width: "201px",
                    }}
                    label="Cancel"
                  />
                  <ButtonPrimary
                    sx={{
                      height: "52px",
                      width: "201px",
                    }}
                    isLoading={loading}
                    label="Submit"
                    onClick={() => {
                      handleSubmitFeedback();
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  );
};

export default FeedbackDialog;
