import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useState } from "react";
import { colors } from "../utils/colors";
import { ImageContainer } from "../shared/components/ImageContainer";
import {
  ArrowRighticon,
  BehanceIcon,
  DribbleIcon,
  InstagramIcon,
  MailIcon,
  WikiLogoBlack,
} from "../assets/icons/icons";
import TextInput from "../shared/components/TextInput";
import MainContainer from "../shared/components/MainContainer";
import {
  toastError,
  toastNotification,
  toastSuccess,
} from "../utils/ToastMessage";
import { subscribeUserToNewsletter } from "../services/user";
import { useNavigate } from "react-router-dom";

type Props = {};

const Footer = (props: Props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [subscribeLoading, setSubscribeLoading] = useState<boolean>(false);

  const handleSubscribeUserToNewsletter = async () => {
    setSubscribeLoading(true);
    try {
      await subscribeUserToNewsletter({
        email: email,
      })
        .then((res: any) => {
          if (res?.isSubscribed) {
            toastSuccess(
              "Successfully subscribed.",
              "User is successfully subscribed to newsletter."
            );
          } else {
            toastNotification(res?.response?.data?.message);
          }
        })
        .catch((err: any) => {
          if (
            err?.response?.data?.message === "This email is already Subscribed."
          ) {
            toastNotification(err?.response?.data?.message);
          } else {
            toastError(
              "Ops! Something went wrong.",
              err?.response?.data?.message
            );
          }
        });
    } catch (error: any) {
      toastError(
        "Ops! Something went wrong.",
        "Failed to subscribe. Please try again."
      );
    } finally {
      setSubscribeLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        bgcolor: colors.primaryGrey,
        gap: "35px",
        width: "100%",
        overflow: "hidden",
        height: "fit-content",
      }}
    >
      <MainContainer
        styleMainContainer={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          gap: "35px",
          padding: "33px 80px 0px 80px",
          width: "100%",
          height: "fit-content",
        }}
      >
        {/* Message Icon */}
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "54px",
            height: "54px",
            borderRadius: "82px",
            bgcolor: "primary.main",
            position: "absolute",
            right: 32,
            bottom: 32,
            cursor: "pointer",
          }}
        >
          <MessageIconSvg2 width="24px" height="24px" color="black" />
        </Box> */}

        {/* Footer table */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flex: 1,
            gap: "35px",
          }}
        >
          {/* Logo */}
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <ImageContainer
              style={{ width: "54px", height: "54px" }}
              height="54px"
              title="Main Logo"
            >
              {WikiLogoBlack}
            </ImageContainer>
          </Box>

          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "66px",
            }}
          >
            {/* Navigation table */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: "44px",
                flex: 1,
                paddingTop: "14px",
              }}
            >
              {/* Column 1 */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "32px",
                  minWidth: "152px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "26px",
                  }}
                >
                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/search");
                    }}
                    color={"rgba(255, 255, 255, 0.4)"}
                    variant="subtitle1"
                  >
                    Explore
                  </Typography>

                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/faq");
                    }}
                    color={"rgba(255, 255, 255, 0.4)"}
                    variant="subtitle1"
                  >
                    FAQ
                  </Typography>

                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/pricing");
                    }}
                    color={"rgba(255, 255, 255, 0.4)"}
                    variant="subtitle1"
                  >
                    Pricing
                  </Typography>
                </Box>
              </Box>

              {/* Column 2 */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "32px",
                  minWidth: "152px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "26px",
                  }}
                >
                  <Typography
                    color={"rgba(255, 255, 255, 0.4)"}
                    variant="subtitle1"
                  >
                    Licensing
                  </Typography>
                  <Typography
                    color={"rgba(255, 255, 255, 0.4)"}
                    variant="subtitle1"
                  >
                    Terms of use
                  </Typography>
                  <Typography
                    color={"rgba(255, 255, 255, 0.4)"}
                    variant="subtitle1"
                  >
                    Privacy Policy
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box>
              <Typography
                sx={{ color: "rgba(255, 255, 255, 0.2)" }}
                fontWeight={"300"}
                variant="body2"
              >
                &copy; 2024, Omega Orion Pvt. Ltd.
              </Typography>
            </Box>
          </Box>

          {/* Contact and subscribe Links */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "32px",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                width: "fit-content",
              }}
            >
              <Typography
                sx={{ lineHeight: "130%" }}
                color={"text.secondary"}
                variant="subtitle1"
              >
                Join our monthly newsletter 🙌
              </Typography>

              <TextInput
                inputWrapStyle={{
                  width: "308px",
                  maxWidth: "308px !important",
                  minWidth: "308px !important",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                value={email}
                placeholder="designer@example.com"
                onChange={(e: any) => {
                  setEmail(e.target.value);
                }}
                disable={true}
                icon={
                  subscribeLoading ? (
                    <CircularProgress
                      size={16}
                      sx={{
                        color: "black",
                      }}
                    />
                  ) : (
                    ArrowRighticon
                  )
                }
                icononclick={() => {
                  !subscribeLoading && handleSubscribeUserToNewsletter();
                }}
                iconstyles={{
                  right: "0px",
                }}
                iconContainerStyles={{
                  marginRight: "-10px",
                  borderRadius: "9px",
                  backgroundColor: "#CCFF00",
                  width: "36px",
                  height: "36px",
                  alignSelf: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  top: "auto",
                  bottom: "auto",
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "100%",
              }}
            >
              <Typography color={"text.secondary"} variant="subtitle1">
                Connect with us
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "16px",
                  width: "100%",
                }}
              >
                {/* Dribble */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "54px",
                    height: "54px",
                    borderRadius: "82px",
                    bgcolor: "rgba(255, 255, 255, 0.02)",
                    boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                    cursor: "pointer",
                  }}
                >
                  <ImageContainer
                    style={{
                      width: "24px",
                      height: "24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    title="dribble"
                  >
                    {DribbleIcon}
                  </ImageContainer>
                </Box>

                {/* Behance */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "54px",
                    height: "54px",
                    borderRadius: "82px",
                    bgcolor: "rgba(255, 255, 255, 0.02)",
                    boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                    cursor: "pointer",
                  }}
                >
                  <ImageContainer
                    style={{
                      width: "24px",
                      height: "24px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    title="dribble"
                  >
                    {BehanceIcon}
                  </ImageContainer>
                </Box>

                {/* Instagram */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "54px",
                    height: "54px",
                    borderRadius: "82px",
                    bgcolor: "rgba(255, 255, 255, 0.02)",
                    boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                    cursor: "pointer",
                  }}
                >
                  <ImageContainer
                    style={{
                      width: "24px",
                      height: "24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    title="dribble"
                  >
                    {InstagramIcon}
                  </ImageContainer>
                </Box>

                {/* Mail */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "54px",
                    height: "54px",
                    borderRadius: "82px",
                    bgcolor: "rgba(255, 255, 255, 0.02)",
                    boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                    cursor: "pointer",
                  }}
                >
                  <ImageContainer
                    style={{
                      width: "24px",
                      height: "24px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    title="dribble"
                  >
                    {MailIcon}
                  </ImageContainer>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Typography
          sx={{
            fontSize: "401px !important",
            fontFamily: "Secular One",
            width: "100%",
            color: "rgba(255, 255, 255, 0.1)",
            textAlign: "center",
            lineHeight: "100%",
            marginBottom: "-170px !important",
            letterSpacing: "20px",
          }}
          variant="h1"
        >
          uiwiki
        </Typography>
        {/* <img
          style={{ width: "100%", paddingTop: "60px" }}
          alt="text"
          src={UiWikiTextBg}
        /> */}
      </MainContainer>
    </Box>
  );
};

export default Footer;
