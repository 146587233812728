import { Box, Typography } from "@mui/material";
import React from "react";
import Chip from "../../shared/components/Chip";
import NewsletterSection from "../../main/NewsletterSection";
import FaqQuestion from "./FaqQuestion";

type Props = {};

const FaqScreen = (props: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "80px",
        alignItems: "center",
        gap: "80px",
        width: "100%",
      }}
    >
      {/* ------Header Section------ */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <Chip
          sx={{
            height: "48px",
            borderRadius: "79px",
            width: "182px",
          }}
          title="Your Go-To Guide"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: "16px",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: "600",
              fontSize: "58px",
              width: "60%",
              lineHeight: "110%",
              background:
                "linear-gradient(81deg, rgba(255,255,255,1) 0%, rgba(48,48,48,1) 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
            variant="h1"
          >
            Frequently asked question
          </Typography>
          <Typography
            variant="h4"
            sx={{ color: "rgba(255, 255, 255, 0.5)", fontWeight: "400" }}
          >
            Quick Answers to common Queries
          </Typography>
        </Box>
      </Box>

      {/* ------Content Section------ */}
      <>
        {/* -----General----- */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
            width: "100%",
            maxWidth: "900px",
          }}
        >
          <Typography sx={{ color: "white" }} variant="h3">
            General
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "100%",
            }}
          >
            <FaqQuestion
              question="What is UIwiki ?"
              description={`Ulwiki is a comprehensive toolkit providing versatile component sets for front-end development. It streamlines the design process with ready-to-use templates and resources.
  
  Enhance your design efficiency with customizable components that fit various project needs. Ulwiki offers seamless integration with multiple platforms and frameworks.`}
            />

            <FaqQuestion
              question="What features does UIwiki offer ?"
              description={`Ulwiki is a comprehensive toolkit providing versatile component sets for front-end development. It streamlines the design process with ready-to-use templates and resources.
  
  Enhance your design efficiency with customizable components that fit various project needs. Ulwiki offers seamless integration with multiple platforms and frameworks.`}
            />

            <FaqQuestion
              question="How do I contact support for UIwiki ?"
              description={`Ulwiki is a comprehensive toolkit providing versatile component sets for front-end development. It streamlines the design process with ready-to-use templates and resources.
  
  Enhance your design efficiency with customizable components that fit various project needs. Ulwiki offers seamless integration with multiple platforms and frameworks.`}
            />
          </Box>
        </Box>

        {/* ------Components------ */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
            width: "100%",
            maxWidth: "900px",
          }}
        >
          <Typography sx={{ color: "white" }} variant="h3">
            Components
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "100%",
            }}
          >
            <FaqQuestion
              question="What types of component sets are included in Ulwiki ?"
              description={`Ulwiki includes a wide range of component sets such as buttons forms navigation bars and modals.
                            Each component is desioned to be fully customizable to fit vour specific proiect needs.

    Additionally, you'll find pre-built templates for dashboards, landing pages, and e-commerce sites.
    These components and templates are compatible with various frameworks, ensuring seamless integration.`}
            />

            <FaqQuestion
              question="How do I integrate UIwiki with my existing projects ?"
              description={`Ulwiki is a comprehensive toolkit providing versatile component sets for front-end development. It streamlines the design process with ready-to-use templates and resources.
  
  Enhance your design efficiency with customizable components that fit various project needs. Ulwiki offers seamless integration with multiple platforms and frameworks.`}
            />

            <FaqQuestion
              question="Does UIwiki offer team collaboration features ?"
              description={`Ulwiki is a comprehensive toolkit providing versatile component sets for front-end development. It streamlines the design process with ready-to-use templates and resources.
  
  Enhance your design efficiency with customizable components that fit various project needs. Ulwiki offers seamless integration with multiple platforms and frameworks.`}
            />
          </Box>
        </Box>

        {/* -------Pricing------- */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
            width: "100%",
            maxWidth: "900px",
          }}
        >
          <Typography sx={{ color: "white" }} variant="h3">
            Pricing
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "100%",
            }}
          >
            <FaqQuestion
              question="What types of component sets are included in Ulwiki ?"
              description={`Ulwiki includes a wide range of component sets such as buttons forms navigation bars and modals.
                            Each component is desioned to be fully customizable to fit vour specific proiect needs.

    Additionally, you'll find pre-built templates for dashboards, landing pages, and e-commerce sites.
    These components and templates are compatible with various frameworks, ensuring seamless integration.`}
            />

            <FaqQuestion
              question="How do I integrate UIwiki with my existing projects ?"
              description={`Ulwiki is a comprehensive toolkit providing versatile component sets for front-end development. It streamlines the design process with ready-to-use templates and resources.
  
  Enhance your design efficiency with customizable components that fit various project needs. Ulwiki offers seamless integration with multiple platforms and frameworks.`}
            />

            <FaqQuestion
              question="Does UIwiki offer team collaboration features ?"
              description={`Ulwiki is a comprehensive toolkit providing versatile component sets for front-end development. It streamlines the design process with ready-to-use templates and resources.
  
  Enhance your design efficiency with customizable components that fit various project needs. Ulwiki offers seamless integration with multiple platforms and frameworks.`}
            />
          </Box>
        </Box>
      </>

      {/* -----Newsletter Section----- */}
      <Box sx={{ width: "100%" }}>
        <NewsletterSection />
      </Box>
    </Box>
  );
};

export default FaqScreen;
