import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  paperClasses,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DrawerExpandlessIcon } from "../../assets/icons/DrawerExpandlessIcon";
import { FilterIconSvg } from "../../assets/icons/FilterIconSvg";
import Chip from "../../shared/components/Chip";

const drawerWidth = 280;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  borderRight: "1px solid rgba(255, 255, 255, 0.12)",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  //   padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

type Props = {
  activeFilters: any;
  handleChangeActiveFilters: any;
};

const FilterOptionsSidebar = (props: Props) => {
  const [showFiltermenu, setShowFiltermenu] = useState(true);
  const [open, setOpen] = React.useState(true);
  const [expandedCategory, setExpandedCategory] = useState(true); // Initially expanded
  const [expandedTags, setExpandedTags] = useState(true); // Initially expanded

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleAccordionToggleTags = () => {
    setExpandedTags(!expandedTags); // Toggle the expanded state
  };
  const handleAccordionToggleCategory = () => {
    setExpandedCategory(!expandedCategory); // Toggle the expanded state
  };

  const handleDrawerClose = () => {
    if (window.innerWidth < 1280) setShowFiltermenu(false);
    else {
      setOpen(false);
    }
  };

  useEffect(() => {}, [props?.activeFilters]);

  return (
    <>
      {showFiltermenu && (
        <Drawer
          sx={{
            display: { xs: showFiltermenu ? "block" : "none", lg: "block" },
            [`& .${paperClasses.root}`]: {
              border: "none",
              position: "none",
              "&:hover": {},
            },
            "& .MuiDrawer-paper": {
              position: "initial !important",
              bgcolor: "transparent",
            },
          }}
          variant="permanent"
          open={open}
        >
          {/* Toggle expand button */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              zIndex: 1,
              width: "100%",
              borderRadius: "0px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon sx={{ width: "100%", height: "100%", opacity: 0 }} />
          </IconButton>

          <DrawerHeader
            sx={{
              display: "flex",
              justifyContent: open ? "space-between" : "center",
              minHeight: "0px !important",
              borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
              alignContent: "center",
              padding: "26px 24px",
            }}
          >
            {/* Toggle collapse button */}
            {open && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
                // onClick={handleDrawerClose}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    width: "100%",
                  }}
                >
                  <FilterIconSvg />
                  <Typography
                    color={"white"}
                    fontWeight={600}
                    variant="subtitle1"
                  >
                    Filter
                  </Typography>
                </Box>
                {/* <DrawerExpandlessIcon color="white" /> */}
              </Box>
            )}
          </DrawerHeader>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "100%",
              height: "100%",
              padding: "16px 16px 0px 24px",
              gap: "16px",
            }}
          >
            {/* --------Category filter container-------- */}
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Accordion
                sx={{
                  width: "100%",
                  bgcolor: "transparent",
                  backgroundImage: "none",
                }}
                expanded={expandedCategory}
                onChange={handleAccordionToggleCategory}
              >
                <AccordionSummary
                  sx={{
                    width: "100%",
                    bgcolor: "transparent",
                    color: "white",
                    padding: "0px",
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  Categories
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                    padding: "0px",
                  }}
                >
                  {[
                    "Headers",
                    "Footer",
                    "FAQ",
                    "Navigation",
                    "Testimonial",
                    "Feature",
                    "Blogs",
                    "Team",
                    "How It Works",
                    "Banner",
                  ].map((tag: any) => {
                    const isSelected =
                      props?.activeFilters?.categories?.includes(tag);
                    return (
                      <Chip
                        key={tag}
                        sx={{ cursor: "pointer" }}
                        title={tag}
                        isActive={isSelected && true}
                        onClick={() =>
                          props?.handleChangeActiveFilters("categories", tag)
                        }
                      />
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box
              style={{
                border: "1px dashed rgba(255, 255, 255, 0.12)",
                height: "1px",
                width: "100%",
              }}
            />
            {/* ---------Tags filter container--------- */}
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Accordion
                sx={{
                  width: "100%",
                  bgcolor: "transparent",
                  backgroundImage: "none",
                }}
                expanded={expandedTags}
                onChange={handleAccordionToggleTags}
              >
                <AccordionSummary
                  sx={{
                    width: "100%",
                    bgcolor: "transparent",
                    color: "white",
                    padding: "0px",
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  Tags
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                    padding: "0px",
                  }}
                >
                  {["Business", "Creative", "Hero", "Professional"].map(
                    (tag) => {
                      const isSelected =
                        props?.activeFilters?.tags?.includes(tag);
                      return (
                        <Chip
                          key={tag}
                          isActive={isSelected && true}
                          sx={{ cursor: "pointer" }}
                          title={tag}
                          onClick={() =>
                            props?.handleChangeActiveFilters("tags", tag)
                          }
                        />
                      );
                    }
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Drawer>
      )}
    </>
  );
};

export default FilterOptionsSidebar;
