import axios from "axios";
import { HOST_NAME } from "../utils/constants";
import Cookies from "js-cookie";

export const updateUserDetails = async (data: any) => {
    try {
        let config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `${HOST_NAME}/user`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Cookies.get("jstoken")}`,
            },
            data: data
        };

        const response = await axios.request(config)
        return response?.data;
    } catch (error) {
        return error;
    }
};

export const getUserById = async (id: string) => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${HOST_NAME}/user/${id}`,
            headers: {
                Authorization: `Bearer ${Cookies.get("jstoken")}`,
            },
        };

        const res = await axios.request(config);
        return res?.data
    } catch (error) {
        return error
    }
}

export const subscribeUserToNewsletter = async (data: any) => {
    return new Promise((resolve, reject) => {

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${HOST_NAME}/newsletter`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Cookies.get("jstoken")}`,
            },
            data: data
        };

        axios
            .request(config)
            .then((response) => {
                resolve(response?.data?.data);
            })
            .catch((error) => {
                reject(error);
            });

    })
}