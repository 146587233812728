import { Box, Typography } from "@mui/material";
import React from "react";
import { DocumentIconSvg } from "../../assets/icons/DocumentIconSvg";

type Props = {
  data: any;
};

const PurchaseCard = (props: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "stretch",
          width: "100%",
          gap: "24px",
        }}
      >
        {/* -----Image container----- */}
        <Box
          sx={{
            maxWidth: "180px",
            maxHeight: "120px",
            width: "100%",
            height: "100%",
            aspectRatio: "3/2",
            borderRadius: "10px",
            boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
              aspectRatio: "3/2",
              borderRadius: "10px",
            }}
            alt="cover-image"
            src={props?.data?.image || ""}
          />
        </Box>

        {/* ------Details Section------ */}
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            paddingTop: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "space-between",
                height: "100%",
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "12px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    color: "#FFFFFF",
                  }}
                  variant="subtitle1"
                >
                  All-Access Pass Lifetime
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    color: "rgba(255, 255, 255, 0.5)",
                  }}
                  variant="body1"
                >
                  Single user License
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  boxShadow: "inset 0px 0px 0px 1px rgba(255, 255, 255, 0.06)",
                  width: "40px",
                  height: "40px",
                  cursor: "pointer",
                }}
              >
                <DocumentIconSvg />
              </Box>
            </Box>
          </Box>

          <Typography
            sx={{
              fontWeight: "600",
              color: "white",
            }}
            variant="subtitle1"
          >
            $132
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PurchaseCard;
